<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="knowledge" :query="{}"> </Mural>
    <main class="lesson-content q-my-lg">
      <div class="content-new-training flex row col-12">
        <div class="content-form-training col-12">
          <form>
            <div class="col-12 flex row q-pa-sm">
              <div class="form-input col-12 column">
                <div class="row">
                  <q-card class="my-card col-12" flat bordered>
                    <q-img
                      :src="trail.image"
                      height="200px"
                    />
                    <q-card-section>
                      <div class="text-overline text-orange-9">{{trail.start_date}} até {{trail.end_date}}</div>
                      <div class="text-h5 q-mt-sm q-mb-xs">{{trail.title}}</div>
                      <div class="col-12 thumb-training">
                          <q-item>
                              <q-item-section>
                                  <q-item-label> <q-icon name="format_align_left" size="sm"/> Descrição</q-item-label> 
                              </q-item-section>
                          </q-item>
                          <q-item>
                              <q-item-section>
                                  <q-item-label v-html="trail.description"/> 
                              </q-item-section>
                          </q-item>
                          <q-separator spaced inset />
                    </div>
                    <div class="col-12 thumb-training">
                          <q-item>
                              <q-item-section>
                                  <q-item-label> <q-icon name="gavel" size="sm"/> Termos para finalização</q-item-label> 
                              </q-item-section>
                          </q-item>
                          <q-item>
                              <q-item-section>
                                  <q-item-label v-html="messageToFinish" />
                              </q-item-section>
                          </q-item>
                          <q-separator spaced inset />
                    </div>
                    
                      <div class="text-caption text-grey">
                         <q-item>
                              <q-item-section>
                                  <q-item-label>  <q-checkbox  v-model="readAndAccept" /> Li e aceito todos os termos</q-item-label> 
                              </q-item-section>
                          </q-item>                          
                          <q-separator spaced inset />
                      </div>
                    </q-card-section>

                    <q-card-section>
                      <DefaultBtn
                        titleButton="Finalizar Curso"
                        icon="edit"
                        :disable="!readAndAccept"
                        @click="finishedTrail"
                      />
                    </q-card-section>
                  </q-card>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
    <q-inner-loading
        :showing="loading"
        label="Por favor aguarde..."
        label-class="text-teal"
        label-style="font-size: 1.1em"
      >
        <div class="collumn justify-center q-my-md">
          <div style="height: auto; width: 50px; margin: auto">
            <LoadingIcon />
          </div>
          <label>Por favor aguarde...</label>
        </div>
      </q-inner-loading>
  </div>
</template>

<script>
import TrailDataServices from "@/services/TrailDataServices";
import GeneralServices from "@/services/GeneralServices";
import LoadingIcon from "@/components/LoadingIcon.vue";

import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import Mural from "@/components/Mural.vue";
import { ref, reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { date, useQuasar } from "quasar";
import { ENV  } from "@/utils/env";

export default {
  name: "FinishTrail",
  components: {
    Mural,
    DefaultBtn,
    LoadingIcon
  },
  setup() {
    let _trailDataServices = new TrailDataServices();
    let _generalServices = new GeneralServices();
    let _route = new useRoute();
    let _router = new useRouter();
    let _courseId = _route.query.courseId;
   
    let $q = new useQuasar();

    let breadcrumbs = ref(["Finalizar Curso"]);
    let loading = ref(false);
    let readAndAccept = ref(false);
    let avaliableCertificate = ref(false);
    let sendCertificate = ref(false);
    let messageToFinish = ref("");
    let trail = reactive({
      title: "",
      short_desc: "",
      description: "",
      start_date: "",
      end_date: "",
      image:"",
      students: []
    });

    onMounted(() => {
      if (_route.query.courseId == null) {
        _router.push({ name: "knowledge" });
        _alertOf("Parâmetro courseId inválido.");
      }
      _getMessageFinished();
      _getTrail();
    });

    async function _getMessageFinished(){
      await _generalServices.getTermsOfUserFinished().then((response) => {
        messageToFinish.value = response.data.text;
      });
    }


     async function _getTrail(){
      if(_courseId == null) return null;
      loading.value = true;
      var response = await _trailDataServices.getTrail(_courseId);

      const startDateformattedString = date.formatDate(response.data.start_date, 'DD/MM/YYYY');
      const endDateformattedString = date.formatDate(response.data.end_date, 'DD/MM/YYYY');
     
      trail.title = response.data.title;
      trail.short_desc = response.data.short_desc;
      trail.description = response.data.description;
      trail.start_date = startDateformattedString;
      trail.end_date = endDateformattedString;
      trail.image =ENV.URL_AWS_BUCKET + response.data.course_image;
      loading.value = false;
    }

    async function finishedTrail(){
      await _trailDataServices.finishedTrail(_courseId).then(() => {
        _router.push({ name: "knowledge" });
        _alertOf(`Curso ${trail.title} finalizado com sucesso`, 'green-9');
        loading.value = false;
      }).catch((error) =>{
        _alertOf(error.response.data.toString());
        loading.value = false;
      })
    }

    function _alertOf(message, color = "red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
    }



    return {
      breadcrumbs,
      loading,
      messageToFinish,
      readAndAccept,
      avaliableCertificate,
      sendCertificate,
      trail,
      finishedTrail,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .lesson-content {
    width: 100%;
    height: 75vh;
    overflow-y: auto;
  }
}

.main-content {
  width: 100%;
  height: 100%;

  .title-form {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    opacity: 0.45;
  }

  .content-new-training {
    height: 68vh;

    .content-thumb-training {
      height: 100%;
      .thumb-training {
        border-radius: 10px;
        .q-img {
          border-radius: 10px;
        }
      }
      .btn-thumb-training {
        .q-btn {
          text-transform: none !important;
          border-radius: 8px;
        }
      }
      .label-title-menus {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #686868;
      }
    }
    .content-form-training {
      height: 100%;

      .form-input {
        .title-input-form {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          opacity: 0.45;
        }
      }
    }
  }

  @media (min-width: 800px) {
    flex-direction: row;
  }
}
</style>